import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Title, Meta, MetaDefinition } from '@angular/platform-browser';
import {
    NavigationEnd,
    Router,
    Event,
    NavigationCancel,
    NavigationError,
    NavigationStart,
    ActivatedRoute,
} from '@angular/router';
import { delay } from 'rxjs/operators';
import { AppService } from './app.service';
import { HttpCancelService } from './core/interceptors/token-interceptor.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
    @ViewChild('scrollingBlock', { static: false }) scrollingBlock;
    title = 'Monitor IQ';
    showAppSpinner: boolean = false;
    routeChangeProgressBar: boolean = false;
    currentSpinnerTitle: string = '';
    constructor(
        private router: Router,
        private appSrvc: AppService,
        private httpCancelService: HttpCancelService,
        private pageTitleSrvc: Title,
        private metaService: Meta,
        private acRoute: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.metaService.addTag({
            httpEquiv: 'Cache-Control',
            content: 'no-cache, no-store, must-revalidate',
        });
        this.metaService.addTag({
            httpEquiv: 'Pragma',
            content: 'no-cache',
        });
        this.metaService.addTag({
            httpEquiv: 'Expires',
            content: '0',
        });

        this.router.events.subscribe((event: Event) => {
            switch (true) {
                case event instanceof NavigationStart: {
                    this.routeChangeProgressBar = true;
                    break;
                }
                case event instanceof NavigationEnd: {
                    // Cancel pending calls
                    this.routeChangeProgressBar = this.showAppSpinner = false;
                    this.httpCancelService.cancelPendingRequests();
                    const rt = this.getActivatedRoute(this.acRoute);
                    rt.data.subscribe((data) => {
                        const { url }: any = event;
                        let currentTitle = data?.title;
                        this.currentSpinnerTitle = '';
                        if (url?.includes('editor')) {
                            currentTitle = 'Editor';
                            this.currentSpinnerTitle = 'editor-spinner';
                        }
                        if (url?.includes('settings')) {
                            currentTitle = 'Settings';
                        }
                        if (currentTitle != undefined) {
                            this.pageTitleSrvc.setTitle(
                                this.title + '::' + currentTitle
                            );
                        } else {
                            this.pageTitleSrvc.setTitle(this.title);
                        }

                        this.appSrvc.appSubject.next({
                            type: 'editor',
                            ...data,
                            ...event,
                        });
                        if (data.title) {
                            this.appSrvc.updateUserHistory(
                                data.title,
                                `Opened ${data.title} page`
                            );
                        }
                    });
                    break;
                }
                case event instanceof NavigationCancel:
                case event instanceof NavigationError: {
                    this.routeChangeProgressBar = false;
                    break;
                }
                default: {
                    break;
                }
            }
        });
    }
    ngAfterViewInit() {
        this.appSrvc.appObservable.pipe(delay(0)).subscribe((item) => {
            if (item.type == 'spinner') {
                this.showAppSpinner = item.value;
            }
        });
    }

    getActivatedRoute(activatedRoute: ActivatedRoute) {
        if (activatedRoute.firstChild) {
            return this.getActivatedRoute(activatedRoute.firstChild);
        } else {
            return activatedRoute;
        }
    }
}
