//  Main use of this ts file to get the access of common functions we need through out the application

import {
    FormControl,
    FormGroup,
    Validators,
    AbstractControl,
} from '@angular/forms';
import { Observable, empty, of, throwError } from 'rxjs';
import * as moment from 'moment-timezone';
import { Angular2Csv } from 'angular2-csv';

// COMMON FUNCTION TO TRACK USER ACTIONS
export const actionsToTrack = {
    CREATE: 'create',
    DELETE: 'delete',
    EXPORT: 'export',
    SHARE: 'share',
    DOWNLOAD: 'download',
    MERGE: 'merge',
    SEND: 'send',
};
export const pagesToTrack = {
    EDITOR: 'Editor',
    RECORDINGS: 'Recordings',
    CLIPS: 'Clips',
    VIEWER: 'Viewer',
    SEARCH: 'Search',
    SETTINGS: 'Settings',
};
export const entitiesToTrack = {
    BOOKMARK: 'bookmark',
    CUTCLIP: 'cutclip',
    RECORDINGS: 'recordings',
    CLIPS: 'clips',
    FINGERPRINT: 'fingerprint',
    HQIMAGE: 'hqimage',
    CUETRIGGERS: 'cuetriggers',
    METADATA: 'metadata',
    CAPTIONS: 'captions',
    LOUDNESS: 'loudness',
};
const userHistoryTextLangJson = {
    recordings_delete_recordings:
        'Recordings are deleted in the recordings page',
    recordings_send_recordings: 'Recordings are sent from the recordings page',
    recordings_merge_recordings: 'Recordings are merged in the recordings page',
    metadata_download_recordings:
        'Metadata is downloaded in the recordings page',
    recordings_share_recordings:
        'Recordings are shared to social sites in the recordings page',
    clips_delete_clips: 'Clips are deleted in the clips page',
    clips_send_clips: 'Clips are sent from the clips page',
    clips_merge_clips: 'Clips are merged in the clips page',
    metadata_download_clips: 'Metadata is downloaded in the clips page',
    clips_share_clips: 'Clips are shared to social sites in the clips page',
    bookmark_delete_clips: 'Bookmarks are deleted in the clips page',
    recordings_delete_search: 'Recordings are deleted in the search page',
    clips_delete_search: 'Clips are deleted in the search page',
    bookmark_delete_search: 'Bookmarks are deleted in the search page',
    metadata_download_search: 'Metadata is downloaded in the search page',
    clips_share_search: 'Clips are shared to social sites in the search page',
    bookmark_create_editor: 'Bookmark is created in the editor page',
    clips_share_editor: 'Clips are shared to social sites in the editor page',
    cutclip_export_editor: 'Cutclip is exported in the editor page',
    captions_export_editor: 'Captions are exported in the editor page',
    loudness_export_editor: 'Loudness is exported in the editor page',
    cutclip_download_editor: 'Cutclip is downloaded in the editor page',
    cuetriggers_download_editor: 'Cuetriggers is downloaded in the editor page',
    hqimage_download_editor: 'HQ Image is downloaded in the editor page',
    fingerprint_create_editor: 'Fingerprint is created in the editor page',
    bookmark_create_viewer: 'Bookmark is created in the viewer page',
    cutclip_export_viewer: 'Cutclip is exported in the viewer page',
};

// END COMMON FUNCTION TO TRACK USER ACTIONS

// Setting the localstorage on user logs in
export function storeUserLogin(val): void {
    localStorage.setItem('authData', JSON.stringify(val));
    // Storing in seperate key as we delete authData on logout.
    setAppLang(val?.data?.language);
}

export function setAppLang(lang: string): void {
    localStorage.setItem('app_lang', lang || 'en');
}

export function getAppLang(): string {
    const lang = localStorage.getItem('app_lang') || 'en';
    return lang === 'English' ? 'en' : (lang === 'Arabic' ? 'ar' : lang);
}

// Retrieving the localstorage when there is necessity in application
export function retrieveUserLogin() {
    return localStorage.getItem('authData');
}

// Deleting the localstorage when there is necessity in application
export function deleteUserLogin() {
    return localStorage.removeItem('authData');
}

// Retrieve hostname
export function retrieveHostName() {
    const authData = JSON.parse(localStorage.getItem('authData'));
    return authData?.data?.hostname || '';
}

// Retrieve hostname
export function retrieveDefaultRoute() {
    const authData = JSON.parse(localStorage.getItem('authData'));
    return authData?.data?.home_page?.toLowerCase() || 'recordings';
}

// Get Bearer token
export function getBearerToken(): string {
    const authData = JSON.parse(localStorage.getItem('authData'));
    return authData?.data?.bearer_token || '';
}

// Get Permissions List
export function getPermissionsList(): Array<any> {
    const authData = JSON.parse(localStorage.getItem('authData'));
    return authData?.data?.permissions || [];
}

// Get Timezone
export function getTimeZone(timezone?): string {
    if (timezone) {
        return timezone;
    }
    const authdata = JSON.parse(localStorage.getItem('authData'));
    if (authdata.data.timezone) {
        return authdata.data.timezone;
    } else {
        return 'America/New_York';
    }
}

// Get userName
export function getUserName(): string {
    const authData = JSON.parse(localStorage.getItem('authData'));
    return authData?.data?.name || '';
}
// Get auto log off time
export function getTimeOutSession(): number {
    const authData = JSON.parse(localStorage.getItem('authData'));
    return authData?.data?.timeout || '';
}

// Validating the form on submission
export function validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
        const control = formGroup.get(field);
        if (control instanceof FormControl) {
            control.markAsTouched({ onlySelf: true });
        } else if (control instanceof FormGroup) {
            validateAllFormFields(control);
        }
    });
}

// Memoisation Function to cache the previous searched data . Perfomance Improves with this
export function memoize(f: any) {
    const cacheLookup = {}; // Value cache stored in the closure
    return function () {
        const key = JSON.stringify(arguments);
        if (key in cacheLookup) {
            return cacheLookup[key];
        } else {
            return (cacheLookup[key] = f.apply(this, arguments));
        }
    };
}

export function toHHMMSS(secs) {
    var sec_num = parseInt(secs, 10);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor(sec_num / 60) % 60;
    var seconds = sec_num % 60;

    return [hours, minutes, seconds]
        .map((v) => (v < 10 ? '0' + v : v))
        .join(':');
}

// convert HHMMSS to Seconds
export function toSeconds(tmStamp) {
    return moment(tmStamp).valueOf() / 1000 || 0;
}

// Download image or file
export function forceDownload(url, fileName, cb?) {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.onload = function () {
        const urlCreator = window.URL || window.webkitURL;
        const imageUrl = urlCreator.createObjectURL(this.response);
        const tag = document.createElement('a');
        tag.href = imageUrl;
        tag.download = fileName;
        document.body.appendChild(tag);
        tag.click();
        document.body.removeChild(tag);
        if (cb && cb instanceof Function) {
            cb();
        }
    };
    xhr.send();
}

export function openDownloadUrl({link, name, newtab}: {[key: string]: any}): void {
    const ele = document.createElement('a');
    ele.setAttribute('href', link);
    if (newtab) {
        // It is showing a allow popup message in browser if host is different.
        ele.setAttribute('target', '_blank');
    }
    if (name) {
        ele.setAttribute('download', name);
    }
    document.body.appendChild(ele);
    ele.click();
    ele.remove();
}

export function updateUserHistory(
    actionType,
    pagetype,
    entityType,
    info
): string {
    const langStringVariable = `${entityType}_${actionType}_${pagetype}`;
    let historyTxt = `${userHistoryTextLangJson[langStringVariable]}`;
    if (actionType === 'merge') {
        historyTxt = `${historyTxt},Mergeclipname :${
            info.media_name
        }, Merged files : ${
            info.storedData ? info.storedData.join(',').replace(/--/g, '/') : ''
        }`;
    }
    if (actionType === 'delete') {
        historyTxt = `${historyTxt},Deleted files : ${
            info.storedData ? info.storedData.join(',').replace(/--/g, '/') : ''
        }`;
    }

    if (actionType === 'send') {
        historyTxt = `${historyTxt}, Exported files to ${info.typeOfSend} : ${
            info.storedData ? info.storedData.join(',').replace(/--/g, '/') : ''
        }`;
    }
    if (actionType === 'share') {
        historyTxt = `${historyTxt}, Shared files to ${info.type} : ${
            info.storedData ? info.storedData.join(',').replace(/--/g, '/') : ''
        }`;
    }

    if (entityType === 'metadata') {
        const templist = [];
        info.data.forEach((item) => {
            templist.push(`${item.format}`);
        });
        historyTxt = `${historyTxt},format: ${templist.join(
            ','
        )}, Metadata files : ${
            info.storedData ? info.storedData.join(',').replace(/--/g, '/') : ''
        }`;
    }

    if (
        [
            'bookmark_create_editor',
            'cutclip_export_editor',
            'cutclip_export_viewer',
            'bookmark_create_viewer',
        ].indexOf(langStringVariable) !== -1
    ) {
        const tempdata = [];
        for (const key in info) {
            if (key) {
                const keyData = info[key];
                tempdata.push(
                    `${keyData.clip_name}/${keyData.cName}/${keyData.host_name}/${keyData.stTime}`
                );
            }
        }
        return `${historyTxt}, Clip data : ${tempdata.join(',')}`;
    }
}

// SESSION STORE FOR USER SETTINGS
export function retrieveCacheOfSettings(key): any {
    try {
        const settingsCacheData = JSON.parse(
            localStorage.getItem('settingsCache')
        );
        if (settingsCacheData.hasOwnProperty(key)) {
            return settingsCacheData[key];
        }
        return;
    } catch (e) {
        return;
    }
}
export function checkIfLocalStorageSettingExist(): any {
    try {
        const settingsCacheData = JSON.parse(
            localStorage.getItem('settingsCache')
        );
        return settingsCacheData;
    } catch (e) {
        return {};
    }
}
export function updateCacheOfSettings(key, value): void {
    try {
        let sessionJson = {};
        if (localStorage.getItem('settingsCache')) {
            sessionJson = JSON.parse(localStorage.getItem('settingsCache'));
        }

        sessionJson[key] = value;

        localStorage.setItem('settingsCache', JSON.stringify(sessionJson));
    } catch (e) {
        console.error(e);
    }
}
// END OF SESSION STORE FOR USER SETTINGS

// For Captions

export const memoizedData: any = memoize(searchCaptionsList);

export class paragraphCaptionsTimesList {
    constructor(private timestampTxt, private valueInSec) {}
}

export function searchCaptionsList(list, searchTxt, key): void {
    if (typeof searchTxt === 'string' && (list as [])) {
        return list?.filter((item: any) =>
            item[key]?.toLowerCase().includes(searchTxt.toLowerCase())
        );
    } else return list;
}

export function pgCaptionsBySecondsList(captions: Array<any>, secondsForSeparation = 0): Array<any> {
    if (captions && secondsForSeparation) {
        const finalCaptionsList = [];
        let initialCaption;
        captions.forEach(caption => {
            caption.valueInSeconds = caption?.valueInSeconds ?? toSeconds(caption.timestamp);
            if (!initialCaption) {
                initialCaption = {...caption};
            } else if (caption.valueInSeconds < initialCaption.valueInSeconds + secondsForSeparation) {
                initialCaption.value += ' / ' + caption.value;
            } else {
                finalCaptionsList.push(initialCaption);
                initialCaption = {...caption};
            }
        });
        finalCaptionsList.push(initialCaption);
        return finalCaptionsList;
    } else {
        captions?.forEach(caption => caption.valueInSeconds = caption?.valueInSeconds ?? toSeconds(caption?.timestamp));
        return captions || [];
    }

    // if ((list as []) && secondsForSeparation) {
    //     const tempPgCaptionsListBySec = [];
    //     const firtsCaptionJson = list[0];
    //     let initialTimeStampBySeparation = firtsCaptionJson?.timestamp;
    //     let tempListString = firtsCaptionJson?.value;
    //     let initialCaptionInSeconds = toSeconds(initialTimeStampBySeparation);

    //     list?.forEach((item) => {
    //         item.valueInSeconds = item?.valueInSeconds ?? toSeconds(item?.timestamp);
    //         if (item?.valueInSeconds && item?.valueInSeconds <= initialCaptionInSeconds) {
    //             if (tempListString !== item?.value) {
    //                 tempListString = tempListString + '/' + item?.value;
    //             }
    //         } else {
    //             tempPgCaptionsListBySec.push({
    //                 timestamp: initialTimeStampBySeparation,
    //                 value: tempListString,
    //                 valueInSeconds: initialCaptionInSeconds,
    //             });
    //             initialCaptionInSeconds =
    //                 initialCaptionInSeconds + secondsForSeparation;
    //             tempListString = item?.value;
    //             initialTimeStampBySeparation = moment(
    //                 initialCaptionInSeconds * 1000
    //             ).format('YYYY-MM-DD HH:mm:ss');
    //         }
    //     });
    //     return tempPgCaptionsListBySec;
    // } else {
    //     return (
    //         list?.map((item) => {
    //             !item?.valueInSeconds
    //                 ? (item.valueInSeconds = toSeconds(item?.timestamp))
    //                 : '';
    //             return item;
    //         }) || []
    //     );
    // }
}

export function dateRangeOverlaps(a_start, a_end, b_start, b_end): boolean {
    if (a_start < b_start && b_start < a_end) { return true; } // b starts in a
    if (a_start < b_end   && b_end < a_end) { return true; } // b ends in a
    if (b_start < a_start && a_end < b_end) { return true; } // a in b
    return false;
}

export function retriveCaptionsBtwTimestamps(
    list,
    stTime,
    endTime
): Array<any> {
    return list.filter(
        (item) =>
            item.valueInSeconds >= stTime && item.valueInSeconds <= endTime
    );
}

export function exportCSV(data = [], fileName = 'export', options = {}): void {
    options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalseparator: '.',
        showLabels: true,
        showTitle: false,
        useBom: true,
        headers: [],
        ...options,
    };
    new Angular2Csv(data, fileName, options);
}

export function strSort(property, sortDirection = 'asc'): (a, b) => number {
    return (a, b): number => {
        if (sortDirection === 'asc') {
            return a[property].localeCompare(b[property]);
        } else {
            return b[property].localeCompare(a[property]);
        }
    };
}

export function replaceText(inputText, searchWith, replaceWith): any {
    if(inputText === undefined) {
        return;
    }

    var regex = new RegExp(searchWith, 'g');
    return inputText.replace(regex, replaceWith);
}
