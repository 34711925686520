import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { ApiService } from '@core/services/api.service';
import { TranslateService } from '@ngx-translate/core';
import {
    deleteUserLogin,
    getAppLang,
    getTimeOutSession,
} from '@shared/functions/common-function';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { AppStateService } from './shared/services/app-state.service';
@Injectable({
    providedIn: 'root',
})
export class AppService {
    // To maintain the state of application
    appEntityJson = {
        recordings: {},
        clips: {},
        bookmarks: {},
        alerts: {},
        search: {},
        frames: {},
        settingsLeftMenuList: {},
    };
    public appSubject = new ReplaySubject<any>();
    public appObservable = this.appSubject.asObservable();
    constructor(
        private apiSrvc: ApiService,
        public translate: TranslateService,
        private snackBar: MatSnackBar,
        private appState: AppStateService,
        public router: Router
    ) {
        translate.addLangs(['en', 'ar']);
        translate.setDefaultLang(getAppLang());
        this.inactivityTime(getTimeOutSession());
        // const browserLang = translate.getBrowserLang();
        // translate.use(browserLang.match(/en|ar/) ? browserLang : 'en');
    }

    openSnackBar(message: string, action: string, duration?): void {
        this.snackBar.open(message, action, {
            duration: duration || 5000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
        });
    }

    updateAppLang(): void {
        this.translate.use(getAppLang() || 'en');
    }

    updateUserHistory(page, activity): void {
        // console.log(pageName, message);
        this.apiSrvc
            .apiRequest('POST', '/user-activity', {}, false, { page, activity })
            .toPromise();
    }

    updateUserPreference(id, params): void {
        this.apiSrvc.apiRequest(
            'PUT',
            `/user-preferences/${id}`,
            {},
            false,
            params
        ).toPromise();
    }

    getUserPreference(params): Observable<any> {
        return this.apiSrvc.apiRequest('GET', '/user-preferences-page', {}, false, params);
    }

    retrieveEntityData(entityType: string) {
        return this.appEntityJson[entityType];
    }

    storeEntityData(entityType, val) {
        this.appEntityJson[entityType] = val;
    }

    clearEntityData(entityType): void {
        this.appEntityJson[entityType] = {};
    }

    // get filtermenu list of channels and groups
    getChannelsList(params) {
        return this.apiSrvc.apiRequest('GET', '/channels', {}, false, {
            ...params,
        });
        // return this.http.get('assets/channels.json');
    }
    getTriggers() {
        return this.apiSrvc.apiRequest('GET', '/alert-triggers', {}, false);
    }
    // get scrollingList
    getScrollingData(url, params): Observable<any> {
        return this.apiSrvc.apiRequest('GET', url, {}, false, params);
        //return this.http.get(url);
    }

    // It is to get the left side menu list
    fetchSettingMenuList(): Observable<any> {
        return this.apiSrvc.apiRequest(
            'GET',
            '/settings_entity_list',
            {},
            false
        );
    }

    // checking auto log off
    inactivityTime(minutes: number): void {
        let time;
        if (minutes) {
            window.onload = resetTimer.bind(this);
            // DOM Events
            document.onmousemove = resetTimer.bind(this);
            document.onkeypress = resetTimer.bind(this);

            function resetTimer() {
                window.clearTimeout(time);

                time = setTimeout(() => {
                    deleteUserLogin();
                    this.router.navigate(['./login']);
                    document.onmousemove = null;
                    document.onkeypress = null;
                }, minutes * 60 * 1000);
            }
        }
    }
    // channels API
    channelAPI(crudType, APIname, data, params): Observable<any> {
        switch (crudType) {
            case 'List':
                return this.apiSrvc.apiRequest(
                    'GET',
                    `/${APIname}`,
                    {},
                    false,
                    params
                );
                break;
        }
    }
    getMiqVersion(): Promise<any> {
        return this.apiSrvc
            .apiRequest('GET', '/miq-version', {}, false, {})
            .toPromise();
    }
    deleteBookmark(params): Observable<any> {
        return this.apiSrvc.apiRequest(
            'DELETE',
            '/bookmarks/1',
            {},
            false,
            params
        );
    }
    deleteClip(params): Observable<any> {
        return this.apiSrvc.apiRequest('DELETE', '/clips/1', {}, false, params);
    }
    deleteSearchResults(params): Observable<any> {
        return this.apiSrvc.apiRequest(
            'DELETE',
            '/search/1',
            {},
            false,
            params
        );
    }
    getEncodingsList(params): Observable<any> {
        return this.apiSrvc.apiRequest(
            'GET',
            '/encoding-profiles',
            {},
            false,
            params
        );
    }
    getInprogressClip(params): Observable<any> {
        return this.apiSrvc.apiRequest('GET', '/clips/all', {}, false, params);
    }
    getNSF(): Observable<any> {
        return this.apiSrvc.apiRequest('GET', '/nsf', {}, false, {});
    }
    getFTP(): Observable<any> {
        return this.apiSrvc.apiRequest('GET', '/ftp-export', {}, false, {});
    }

    getTimeZone(): Observable<any> {
        return this.apiSrvc.apiRequest('GET', '/timezone', {}, false, {}).pipe(
            map((result) => {
                if (result.status === 200) {
                    this.appState.setTimezone(result.data.tz);
                }
                return result;
            })
        );
    }

    getAlerts(): Observable<any> {
        return this.apiSrvc.apiRequest('GET', '/alerts?limit=5', {}, false, {});
    }

    getUserPreferences(): Observable<any> {
        return this.apiSrvc.apiRequest(
            'GET',
            '/user-preferences',
            {},
            false,
            {}
        );
    }
}
