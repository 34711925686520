import { Injectable, OnInit } from '@angular/core';
import { webSocket } from 'rxjs/webSocket';
import { environment } from '../../../environments/environment';
import { Observable, timer, Subject, EMPTY } from 'rxjs';
import {
    retryWhen,
    tap,
    delayWhen,
    switchAll,
    catchError,
    delay,
} from 'rxjs/operators';
import { ApiService } from '@core/services/api.service';

export const RECONNECT_INTERVAL = environment.reconnectInterval;

@Injectable({
    providedIn: 'root',
})
export class WebSocketService {
    public socket;

    constructor(private apiService: ApiService) {
        // this.connect();
    }

    /**
     * Creates a new WebSocket subject and send it to the messages subject
     * @param cfg if true the observable will be retried.
     */
    public connect(host_url, host_name?) {
        let socket;
        if (!socket) {
            let url = host_url.includes('https://')
                ? host_url.split('https://')[1]
                : host_url.split('http://')[1];
            if (url.includes('/')) {
                url = url.slice(0, -1);
            }
            socket = webSocket({
                url: host_url.includes('https://')
                    ? `wss://${url}:8000`
                    : `ws://${url}:8000`,
                openObserver: {
                    next: () => {
                        console.log('[WebSocketService]: connection ok');
                    },
                },
                closeObserver: {
                    next: () => {
                        console.log('[WebSocketService]: connection closed');
                    },
                },
            });
            socket
                .pipe(
                    (o) => o,
                    tap({
                        error: (error) => console.log(error),
                    }),
                    catchError((_) => EMPTY)
                )
                .subscribe();
            // console.log(socket);
            //toDO only next an observable if a new subscription was made double-check this
            // this.messagesSubject.next(messages);
        }
        socket['host_name'] = host_name ? host_name : '';
        return socket;
    }

    /**
     * Retry a given observable by a time span
     * @param observable the observable to be retried
     */
    private reconnect(observable: Observable<any>): Observable<any> {
        return observable.pipe(
            retryWhen((errors) =>
                errors.pipe(
                    tap((val) =>
                        console.log('[WebSocketService] Try to reconnect', val)
                    ),
                    delayWhen((_) => timer(RECONNECT_INTERVAL))
                )
            )
        );
    }

    close() {
        // this.socket.complete();
    }

    sendMessage(msg: any) {
        // this.socket.next(msg);
    }
}
