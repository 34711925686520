import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {
    AuthGuardService,
    AuthGuardLoadService,
} from '../app/core/services/authguard/authguard.service';
import {
    AppResolverService,
    EditorResolverService,
} from './app-resolver.service';

const routes: Routes = [
    {
        path: 'auth',
        loadChildren: () =>
            import('@feature/auth/auth.module').then((m) => m.AuthModule),
        canActivate: [AuthGuardService],
        data: { title: 'Login' },
    },
    {
        path: 'recordings',
        loadChildren: () =>
            import('@feature/recordings/recordings.module').then(
                (m) => m.RecordingsModule
            ),
        canLoad: [AuthGuardLoadService],
        data: { permission: 'VIEW_RECORDINGS', title: 'Recordings' },
    },
    {
        path: 'clips',
        loadChildren: () =>
            import('@feature/clips/clips.module').then((m) => m.ClipsModule),
        canLoad: [AuthGuardLoadService],
        data: { permission: 'VIEW_CLIPS', title: 'Clips' },
    },
    {
        path: 'editor',
        loadChildren: () =>
            import('@feature/editor/editor.module').then((m) => m.EditorModule),
        // canLoad: [AuthGuardLoadService],
        data: { permission: 'VIEW_CLIPS', title: 'Editor' },
        resolve: {
            data: EditorResolverService,
        },
    },
    {
        path: 'viewer',
        loadChildren: () =>
            import('@feature/viewer/viewer.module').then((m) => m.ViewerModule),
        canLoad: [AuthGuardLoadService],
        data: { permission: 'VIEW_VIEWER', title: 'Viewer' },
    },
    {
        path: 'monitor',
        loadChildren: () =>
            import('@feature/monitor/monitor.module').then(
                (m) => m.MonitorModule
            ),
        canLoad: [AuthGuardLoadService],
        data: { permission: 'VIEW_MONITOR', title: 'Monitor' },
    },
    {
        path: 'programguide',
        loadChildren: () =>
            import('@feature/program-guide/program-guide.module').then(
                (m) => m.ProgramGuideModule
            ),
        canLoad: [AuthGuardLoadService],
        data: { permission: 'VIEW_MONITOR', title: 'Program Guide' },
    },
    {
        path: 'search',
        loadChildren: () =>
            import('@feature/search/search.module').then((m) => m.SearchModule),
        canLoad: [AuthGuardLoadService],
        data: { permission: 'SEARCH_RECORDINGS', title: 'Search' },
    },
    {
        path: 'settings',
        loadChildren: () =>
            import('@feature/settings/settings.module').then(
                (m) => m.SettingsModule
            ),
        data: { permission: 'MANAGE_APP_SETTINGS', title: 'Settings' },
        resolve: {
            data: AppResolverService,
        },
    },
    {
        path: 'reports',
        loadChildren: () =>
            import('@feature/reports/reports.module').then(
                (m) => m.ReportsModule
            ),
        canLoad: [AuthGuardLoadService],
        data: { permission: '', title: 'Reports' },
    },
    {
        path: 'nopermission',
        loadChildren: () =>
            import('@shared/modules/no-permission/no-permission.module').then(
                (m) => m.NoPermissionModule
            ),
        canLoad: [AuthGuardLoadService],
        data: { permission: '', title: 'No Permission' },
    },
    {
        path: 'ip',
        loadChildren: () =>
            import('@feature/ipconfig/ipconfig.module').then(
                (m) => m.IpconfigModule
            ),
    },
    // Redirect from the root to the "/auth" prefix
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'auth',
    },
    // This is the WILDCARD CATCH-ALL route that is scoped to the entire application
    {
        path: '**',
        redirectTo: 'auth',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            useHash: true,
            scrollPositionRestoration: 'enabled',
            onSameUrlNavigation: 'reload',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
