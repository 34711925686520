<div #scrollingBlock>
    <mat-progress-bar *ngIf="routeChangeProgressBar" color="accent" mode="indeterminate">
    </mat-progress-bar>
    <router-outlet></router-outlet>

    <div class="miq-spinner {{currentSpinnerTitle}}" *ngIf="showAppSpinner">
    </div>
    <button class="sticky-btn-scrollup" matTooltip="Scroll Top" id="appStickyScrollTopBtnId">
        <span class="material-icons">keyboard_arrow_up</span>
    </button>
</div>
