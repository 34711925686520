/* Inbuilt Modules Imports */
import { BrowserModule, Meta } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    HttpClient,
    HttpClientModule,
    HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { HotkeyModule } from 'angular2-hotkeys';
// import { NgxJsonViewerModule } from 'ngx-json-viewer';

import { MatProgressBarModule } from '@angular/material/progress-bar';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatSnackBarModule } from '@angular/material/snack-bar';

/* Added Modules */
import { AppRoutingModule } from '@app/app-routing.module';

/* Components Imports */
import { AppComponent } from '@app/app.component';
import { TokenInterceptorService } from '@core/interceptors/token-interceptor.service';

// import { WebSocketModule } from '@shared/modules/websocket.module';
import { WebSocketService } from '@shared/services/websocket';
import { EditorService } from './feature/editor/editor.service';
import { EditorResolverService } from './app-resolver.service';
import { CommonModule, DatePipe } from '@angular/common';
import { HlsjsComponent } from './shared/components/hlsjs/hlsjs.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient, './assets/i18n/');
}

export class GlobalErrorHandler implements ErrorHandler {
    constructor() {}
    handleError(error: Error) {
        if (Error) {
            console.error(error);
            let getFileNameFromException = (e: Error) => {
                const { stack } = e;
                if (!stack) {
                    return;
                }
                const match =
                    /.*\n\s*at\s.*\s\((.*\/)*(.*):([\d]*):([\d]*)/.exec(stack);
                if (!match) {
                    return;
                }
                console.log(match);
                return {
                    fileName: match[1],
                    line: Number(match[2]),
                    column: Number(match[3]),
                };
            };
            // console.log(getFileNameFromException(error));
        } else {
            console.log('');
        }
    }
}

@NgModule({
    exports: [],
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        CommonModule,
        AppRoutingModule,
        HotkeyModule.forRoot(),
        // NgxJsonViewerModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        HttpClientModule,
        MatProgressBarModule,
        MatSnackBarModule,
        FormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptorService,
            multi: true,
        },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler,
        },
        WebSocketService,
        EditorService,
        EditorResolverService,
        DatePipe,
        Meta,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
